<script>
import { ArrowUpIcon, HeartIcon, EyeIcon, ShoppingCartIcon } from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
import API from "@/core/api";
import IndexService from "@/MainServices/IndexService.js";
import Button from '../../pages/vue-bootstrap/Button.vue';
import Skeleton from "@/view/front/components/skeleton";

/**
 * Shop-product-detail component
 */
export default {
  data() {
    return {
      countval: 1,
      API: API.API_HOME,
      searchedUniversity:null,
      search:false,
      universites:[],
      filterUniversitesData: [],
      loading: true
    };
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    ArrowUpIcon,
    Skeleton
  },
  methods: {
      filterUniversties(e)
      {
       this.filterUniversitesData =   this.universites.filter((el) => {
           return el.value == e
       }) 
       this.search = true;
      },
      clearSearch()
      {
          this.searchedUniversity = null;
          this.search = false;
      }
  },
  mounted() {
    IndexService.getUniversitesWithListings(
     
    ).then((response) => {

       response.data.map((el) => {
this.universites.push({value: el.id, text: el.name, image_path: el.image_path, user_slug : el.user_slug})
     this.loading = false;
     });
    });
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-custom bg-light d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">Universities Directory </h4>
              <div class="page-next"></div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!-- Hero End -->

        <!-- Course list Start -->
    <section class="section" style="padding-bottom:30px">
        <div class="container">
           
            <div class="row justify-content-center">
                 
                 <div class="col-12">
                <button type="button" @click="clearSearch" class="btn btn-primary mt-4 ml-2" style="float: right;">Clear</button>
                 <v-autocomplete
                  :items="universites"
                  v-model="searchedUniversity"
                  label="Search By Name"
                  style="width: 25%;float: right;"
                  @change="filterUniversties($event)"
                ></v-autocomplete>
                </div>
                <div class="col-12">
                    <div class="section-title text-center mb-1 pb-1">
                        <p class="text-muted para-desc mb-0 mx-auto">Select your favourite <span class="text-primary font-weight-bold">University</span> and apply in your dream course.</p>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
                 <Skeleton v-if="loading" /> 

            <div class="row" v-if="!search">
                <div class="col-lg-2 col-md-3 col-12 mt-4 pt-2" v-for="(university, index) in universites" :key="index">
                    <div class="card explore-feature border-0 rounded text-center bg-white" style="min-height:170px; max-height:170px">
                        <div class="card-body py-5">
                            <div class="icon shadow-lg d-inline-block">
                              <img
                  :src='API+`${university.image_path}`'
                  class="img-fluid rounded-top mx-auto"
                  alt=""
                  style="max-height: 80px;"
                />
                            </div>
                            <div class="pt-6">
                                <h5 style="font-size: 12px; font-weight: bold;"><router-link :to="{name: 'UniCourses',  params: { uni_name: university.user_slug.toLowerCase().trim() }}" class="title text-dark">{{university.text}}</router-link></h5>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->
                <!--end col-->
            </div>
            <!--end row-->
            <div class="row" v-if="search">
                <div class="col-lg-2 col-md-3 col-12 mt-4 pt-2" v-for="(university, index) in filterUniversitesData" :key="index">
                    <div class="card explore-feature border-0 rounded text-center bg-white" style="min-height:170px; max-height:170px">
                        <div class="card-body py-5">
                            <div class="icon shadow-lg d-inline-block">
                              <img
                  :src='API+`${university.image_path}`'
                  class="img-fluid rounded-top mx-auto"
                  alt=""
                  style="max-height: 80px;"
                />
                            </div>
                            <div class="pt-6">
                                <h5 style="font-size: 12px; font-weight: bold;"><router-link :to="{name: 'UniCourses',  params: { uni_name: university.user_slug.toLowerCase().trim() }}" class="title text-dark">{{university.text}}</router-link></h5>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->

    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
